import { render, staticRenderFns } from "./Passport.vue?vue&type=template&id=3c4fb500&scoped=true"
import script from "./Passport.vue?vue&type=script&lang=js"
export * from "./Passport.vue?vue&type=script&lang=js"
import style0 from "./Passport.vue?vue&type=style&index=0&id=3c4fb500&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c4fb500",
  null
  
)

export default component.exports