<template>
    <b-modal
      id="delivery-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
    >
      <div class="header">
        <span class="close-icon"
              @click="closeModal">
          <img src="@/assets/svg/close.svg"
               aria-label="close">
        </span>

        <h5>
          Поздравляем с победой! <br>
        </h5>

        <div class="subtext">
          Для получения приза, пожалуйста, заполните информацию ниже:
        </div>
      </div>

      <form ref="form"
            class="modal-form"
            @submit.stop.prevent="submit">
        <b-form-group
          id="name-input"
          label-for="name-input"
          :invalid-feedback="invalidName"
        >
          <b-form-input
            id="name-input"
            placeholder="ФИО (полностью)"
            required
            type="text"
            pattern="[A-Za-z]+"
            :class="{ 'is-invalid' : isInvalidName }"
            v-model="$v.item.name.$model"
            :state="validateState($v.item.name)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
              id="date-input"
              label-for="date-input"
              invalid-feedback="Некорректная дата рождения"
              v-mask="'##.##.####'"
            >
              <b-form-input
                id="date-input"
                placeholder="Дата рождения"
                required
                type="text"
                v-model="$v.item.date.$model"
                :state="validateState($v.item.date)"
              ></b-form-input>
        </b-form-group>

        <b-form-group
          id="series-input"
          label-for="series-input"
          :invalid-feedback="invalidSeries"
        >
          <b-form-input
            id="series-input"
            placeholder="Серия и номер паспорта"
            required
            ref="series"
            maxLength="10"
            v-model="$v.item.series.$model"
            :class="{ 'is-invalid' : isInvalidSeries }"
            :state="validateState($v.item.series)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
              id="passport-date-input"
              label-for="passport-date-input"
              invalid-feedback="Некорректная дата выдачи паспорта"
              v-mask="'##.##.####'"
            >
              <b-form-input
                id="passport-date-input"
                placeholder="Дата выдачи"
                required
                type="text"
                v-model="$v.item.passportDate.$model"
                :state="validateState($v.item.passportDate)"
              ></b-form-input>
        </b-form-group>

        <b-form-group
          id="issued-input"
          label-for="issued-input"
          invalid-feedback=""
        >
          <b-form-input
            id="issued-input"
            placeholder="Кем выдан"
            required
            type="text"
            pattern="[A-Za-z]+"
            v-model="$v.item.issued.$model"
            :state="validateState($v.item.issued)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label-for="inn-input"
          :invalid-feedback="invalidInn"
        >
          <b-form-input
            id="inn-input"
            placeholder="Номер ИНН*"
            v-model="$v.item.inn.$model"
            ref="inn"
            maxLength="20"
            :class="{ 'is-invalid' : isInvalidInn }"
            :state="validateState($v.item.inn)"
            required
          ></b-form-input>
       </b-form-group>

      <b-form-group
        id="registration-input"
        label-for="registration-input"
        invalid-feedback=""
        >
        <b-form-input
            id="registration-input"
            placeholder="Адрес регистрации (прописка)"
            required
            type="text"
            pattern="[A-Za-z]+"
            v-model="$v.item.registration.$model"
            :state="validateState($v.item.registration)"
        ></b-form-input>
        </b-form-group>

        <b-form-group
        style="margin-bottom: 60px;"
        id="delivery-input"
        label-for="delivery-input"
        invalid-feedback=""
        >
        <b-form-input
            id="delivery-input"
            placeholder="Адрес доставки приза"
            required
            type="text"
            pattern="[A-Za-z]+"
            v-model="$v.item.delivery.$model"
            :state="validateState($v.item.delivery)"
        ></b-form-input>
        </b-form-group>
        
        <div class="upload-imgs-block">

        <label>
            <input type="file"
                    @change="fileChangePassportFirstPage"
                    accept="image/jpeg, image/png, image/jpg, image/bmp">

            <div class="d-flex align-items-center">
            <img src="@/assets/checked.png"
                    v-if="passportFirstPage.length >= 1"
                    class="fake-button-img" />

            <img src="@/assets/unchecked.png"
                    v-if="passportFirstPage.length < 1"
                    class="fake-button-img" />

            <div class="fake-button">Загрузить скан/фото паспорта 1-й разворот</div>

            <span class="fake-button-subtext">(до 5мб)</span>
            </div>
        </label>

        <!-- <div class="">
            <div class="uploaded-img"
                v-for="(image, index) in passportFirstPage"
                :key="index">
                {{ image.name }}

                <div class="delete">
                <img @click.prevent="deleteImageFirst(index)"
                    src="@/assets/svg/close.svg"
                    alt="delete">
                </div>
            </div>
        </div> -->

        <div class="error"
                v-if="isInvalidFirstPage">
            {{ invalidFirstPage }}
        </div>

        <label>
            <input type="file"
                    @change="fileChangePassportSecondPage"
                    accept="image/jpeg, image/png, image/jpg, image/bmp">

            <div class="d-flex align-items-center">
            <img src="@/assets/checked.png"
                    v-if="passportSecondPage.length >= 1"
                    class="fake-button-img" />

            <img src="@/assets/unchecked.png"
                    v-if="passportSecondPage.length < 1"
                    class="fake-button-img" />

            <div class="fake-button">Загрузить скан/фото паспорта (прописка)</div>

            <span class="fake-button-subtext">(до 5мб)</span>
            </div>
        </label>

        <!-- <div class="">
            <div class="uploaded-img"
                v-for="(image, index) in passportSecondPage"
                :key="index">
                {{ image.name }}

                <div class="delete">
                <img @click.prevent="deleteImageSecond(index)"
                    src="@/assets/svg/close.svg"
                    alt="delete">
                </div>
            </div>
        </div> -->

        <div class="error"
                v-if="isInvalidSecondPage">
            {{ invalidSecondPage }}
        </div>

        <label>
            <input type="file"
                    @change="fileChangeDocumentInn"
                    accept="image/jpeg, image/png, image/jpg, image/bmp">

            <div class="d-flex align-items-center">
                <img src="@/assets/checked.png"
                    v-if="documentInn.length >= 1"
                    class="fake-button-img" />

                <img src="@/assets/unchecked.png"
                    v-if="documentInn.length < 1"
                    class="fake-button-img" />

                <div class="fake-button">Загрузить скан/фото ИНН</div>

                <span class="fake-button-subtext">(до 5мб)</span>
            </div>
        </label>

        <!-- <div class="">
            <div class="uploaded-img"
                v-for="(image, index) in documentInn"
                :key="index">
                {{ image.name }}

                <div class="delete">
                <img @click.prevent="deleteImageInn(index)"
                    src="@/assets/svg/close.svg"
                    alt="delete">
                </div>
            </div>
        </div> -->

        <div class="error"
                v-if="isInvalidFileInn">
            {{ invalidFileInn }}
        </div>
      </div>
    </form>

    <button class="button button-orange"
              type="submit"
              :disabled="disabledBtn"
              @click="submit">Отправить</button>
  </b-modal>
</template>

<script>
import axios from 'axios'
import { validationMixin } from "vuelidate"
import { mapGetters, mapMutations } from "vuex"
import { required, requiredIf, minLength, maxLength, or, helpers } from "vuelidate/lib/validators"

const text = helpers.regex('text', /^[?!,.а-яА-ЯёЁ\-\s]+$/)
const email = helpers.regex('email', /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
const number = helpers.regex('number', /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
const token = localStorage.getItem('token')

  export default {
    mixins: [validationMixin],

    validations: {
      item: {
        name: {
          required,
          minLength: minLength(2),
          text,
        },
        issued: {
          required,
          minLength: minLength(2),
          text,
        },
        delivery: {
          required,
          minLength: minLength(2),
        },
        registration: {
          required,
          minLength: minLength(2),
        },
        date: {
          required,
          minLength: minLength(2)
        },
        passportDate: {
          required,
          minLength: minLength(2)
        },
        series: {
          required,
          minLength: minLength(10),
          maxLenght: maxLength(10),
          number,
        },
        inn: {
          required,
          minLength: minLength(10),
          number,
        }
      }
    },

    data() {
      return {
        item: {
          name: '',
          date: '',
          passportDate: '',
          series: '',
          inn: '',
          issued: '',
          delivery: '',
          registration: ''
        },
        documentInn: [],
        passportFirstPage: [],
        passportSecondPage: [],
        isInvalidName: null,
        isInvalidDate: null,
        isInvalidSeries: null,
        isInvalidInn: null,
        isInvalidFirstPage: null,
        isInvalidSecondPage: null,
        isInvalidFileInn: null,
        invalidName: 'Некорректное поле',
        invalidDate: 'Некорректное поле',
        invalidSeries: 'Некорректное поле',
        invalidInn: 'Некорректное поле',
        invalidFirstPage: '',
        invalidSecondPage: '',
        invalidFileInn: ''
      }
    },

    computed: {
      ...mapGetters({
        token: 'token'
      }),

      disabledBtn(){
        if (this.$v.item.$anyError)
          return true
      },
    },

    methods: {
      ...mapMutations({
        saveUser: 'saveUser',
        saveToken: 'saveToken',
        getSuccessText: 'getSuccessText',
        getErrorText: 'getErrorText'
      }),

      closeModal(){
        this.$v.$reset()

        this.item.name = '',
        this.item.series = '',
        this.item.inn = '',
        this.item.date = '',
        this.item.issued = '',
        this.item.delivery = '',
        this.item.registration = '',
        this.item.passportDate = '',
        this.isInvalidName = false,
        this.isInvalidSeries = false,
        this.isInvalidInn = false,
        this.isInvalidDate = false,
        this.isInvalidFirstPage = false,
        this.isInvalidSecondPage = false,
        this.isInvalidFileInn = false,
        this.invalidName = 'Некорректное поле',
        this.invalidSeries = 'Некорректное поле',
        this.invalidInn = 'Некорректное поле',
        this.invalidDate = 'Некорректное поле',
        this.invalidFirstPage = '',
        this.invalidSecondPage = '',
        this.invalidFileInn = '',
        this.documentInn = [],
        this.passportFirstPage = [],
        this.passportSecondPage = []

        this.$bvModal.hide('delivery-modal')
      },

      logout(){
        axios.post('auth/logout', {
        }, {
          headers: {
            'Accept': "application/json",
            'Content-Type': "application/json",
            'access-control-allow-origin': "*",
            'Authorization': "Bearer " + localStorage.getItem('token')
          }
        }).then((response) => {
          this.saveUser(null)
          this.saveToken(null)
          this.$router.push('/')

          localStorage.removeItem('token')
        }).catch((error) => {
            console.log(error)
        })
      },

      fileChangePassportFirstPage(event){
        Array.from(event.target.files).forEach(value => {
          this.passportFirstPage.push(value)
        })

        this.isInvalidFirstPage = false
      },

      fileChangePassportSecondPage(event){
        Array.from(event.target.files).forEach(value => {
          this.passportSecondPage.push(value)
        })

        this.isInvalidSecondPage = false
      },

      fileChangeDocumentInn(event){
        Array.from(event.target.files).forEach(value => {
          this.documentInn.push(value)
        })

        this.isInvalidFileInn = false
      },

      deleteImageFirst(index){
        this.passportFirstPage.splice(index, 1)

        this.isInvalidFirstPage = false
      },

      deleteImageSecond(index){
        this.passportSecondPage.splice(index, 1)

        this.isInvalidSecondPage = false
      },

      deleteImageInn(index){
        this.documentInn.splice(index, 1)

        this.isInvalidFileInn = false
      },

      validateState(item) {
        const { $dirty, $error } = item
        return $dirty ? !$error : null
      },

      submit() {
        this.$v.item.$touch()

        if (this.$v.item.$anyError)
          return false

          const formData = new FormData()
          formData.append('file_passport_one', this.passportFirstPage[0])
          formData.append('file_passport_two', this.passportSecondPage[0])
          formData.append('file_inn', this.documentInn[0])
          formData.append('fio', this.item.name)
          formData.append('date_birth', this.item.date)
          formData.append('passport_date', this.item.passportDate)
          formData.append('passport_serial_number', this.item.series)
          formData.append('passport_issued', this.item.issued)
          formData.append('address', this.item.registration)
          formData.append('delivery_address', this.item.delivery)
          formData.append('inn', this.item.inn)

          axios.post('profile/passport', formData, {
          }, {
            headers: {
              'Authorization': "Bearer " + localStorage.getItem('token'),
              'Accept': "application/json"
            }
          }).then((response) => {
            this.closeModal()
            this.getSuccessText('Данные приняты. С вами свяжутся с информацией по вручению Приза')
            this.$bvModal.show('success-modal')
          }).catch((error) => {
             if (error.response.data.errors){
              this.invalidFirstPage = 'Заполните поле'
              this.invalidSecondPage = 'Заполните поле'
              this.invalidFileInn = 'Заполните поле'

              if (error.response.data.errors.file_passport_one){
                this.isInvalidFirstPage = true
              }

              if (error.response.data.errors.file_passport_two){
                this.isInvalidSecondPage = true
              }

              if (error.response.data.errors.file_inn){
                this.isInvalidFileInn = true
              }
            }
        })
      },
    }
  }
</script>

<style scoped lang="scss">
.inputs-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media(max-width: 768px){
    flex-direction: column;
  }

  div {
    width: 49%;

    @media(max-width: 768px){
      width: 100%;
    }
  }
}

.button {
  margin-top: 0px;
  width: 100%;
  max-width: 163px;
  margin-bottom: 15px;

  &:hover {
    opacity: 0.8 !important;
  }
}

a {
  color: $red;
}

.fake-button {
  font-weight: 500;
  font-size: 14px;

  @media(max-width: 576px){
    font-size: 10px;
  }
}

.modal-form {
    width: 100%;
    max-width: 530px;
}

.fake-button-subtext {
  color: #C4C4C4;
  font-weight: 500;
  font-size: 14px;
  margin-left: 5px;

  @media(max-width: 576px){
    font-size: 10px;
    margin-left: 2px;
  }
}

.fake-button-img {
  max-width: 20px;
  margin-right: 15px;

  @media(max-width: 576px){
    margin-right: 10px;
  }
}

label {
  width: 100%;
  margin-bottom: 20px;
  cursor: pointer;
}

.fake-upload {
  color: $black;
  font-size: 18px;
  font-weight: 400;

  &:hover {
    opacity: 0.9;
  }
}

input[type='file'] {
  position: absolute;
  opacity: 0;
  height: 0;
}

.uploaded-img {
  background-color: $white;
  padding: 10px 15px;
  border-radius: 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.delete {
  width: 25px;
  height: 25px;
  background-color: $light-grey;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &:hover {
    opacity: 0.8;
  }

  img {
    max-width: 7px;
    max-height: 7px;
  }
}

.error {
  color: $red;
  font-size: 12px;
  margin-top: -20px;
}

.subtext {
  font-family: $regular;
  margin-bottom: 60px;
  color: $blue;
  font-size: 20px;
  font-weight: 600 !important;
  text-align: center;
  text-transform: uppercase;

  @media(max-width: 576px){
    font-size: 16px;

    br {
      display: none;
    }
  }
}

h5 {
  text-align: center;
  text-transform: uppercase;
  color: $blue;
  font-size: 40px;
  font-family: $regular;
  font-weight: 700 !important;
  margin-top: 22px;
  margin-bottom: 22px;

  @media(max-width: 768px) {
    font-size: 30px;
  }

  @media(max-width: 576px) {
    font-size: 22px;
  }
}

.form-group {
  position: relative;
  font-family: $regular !important;

  .input-icon {
    position: absolute;
    color: $red;
    font-size: 18px;
    font-family: $regular;
    top: 0;
    margin-left: 15px;
    margin-top: 12px;
  }
}

.upload-imgs-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 55px;
}

h3 {
  text-align: center;
}

.form-control {
  color: $darker-grey !important;
  background-color: transparent !important;
  border: 2px solid white;
  transition: .6s all;
  height: 60px;
  display: flex;
  padding-left: 25px !important;
  align-items: center;
  font-size: 18px;
  margin-top: 0px;
  border-radius: 62px;
  max-width: none;
  min-width: none;
  font-family: $regular;
  margin-left: 10px;
  margin-right: 10px;

  &::placeholder {
    color: #C4C4C4 !important;
  }

  &:focus {
    box-shadow: none;
    border: 2px solid $light-blue;
  }

  @media(max-width: 999px){
    min-width: none;
  }

  @media(max-width: 768px){
    min-width: 300px !important;
    max-width: 300px !important;
    margin-left: 0;
    height: 48px;
    margin-right: 0;
  }
}

.form-group {
  margin-bottom: 20px;
}

.button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 30px;

  .button {
    width: 100%;
    max-width: 326px;
    margin-right: 10px;

    @media(max-width: 768px){
      margin-right: 0;
      max-width: 326px;
      height: 48px;
      margin-right: 7px;
      margin-bottom: 20px;
    }
  }
}

.content {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media(max-width: 768px){
    flex-direction: column;
    align-items: center;
  }

  .d-flex {
    @media(max-width: 768px){
      flex-direction: column;
      align-items: center;
    }
  }
}

.invalid-feedback {
    padding-left: 30px !important;
}

.sample {
  max-width: 100px;
  max-height: 160px;
  margin-top: 20px;
  cursor: pointer;

  @media(max-width: 768px){
    margin-top: 0;
    margin-bottom: 24px;
    max-width: 155px;
    max-height: 255px;
  }
}

.button {
  max-width: 326px;
  margin-top: 95px;
}

.form {
  width: 100%;
}

.form-inputs {
  width: 100%;

  @media(max-width: 576px){
    padding: 0 10px !important;
  }
}
</style>
