<template>
  <div id="app">
    <HeaderRegion />

    <router-view />

    <Feedback />

    <Error />

    <Upload />

    <Success />

    <CodeCheck />

    <ClosedModal />

    <UploadVoucher />

    <Passport />

    <FailedVoucher />

    <ManualVoucher />

    <SuccessUpload />

    <FooterRegion />
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapMutations } from "vuex"
import Error from '@/components/modals/Error.vue'
import Upload from '@/components/modals/Upload.vue'
import Success from '@/components/modals/Success.vue'
import Feedback from '@/components/modals/Feedback.vue'
import Passport from '@/components/modals/Passport.vue'
import HeaderRegion from '@/components/HeaderRegion.vue'
import FooterRegion from '@/components/FooterRegion.vue'
import CodeCheck from '@/components/modals/CodeCheck.vue'
import ClosedModal from '@/components/modals/ClosedModal.vue'
import FailedVoucher from '@/components/modals/FailedVoucher.vue'
import UploadVoucher from '@/components/modals/UploadVoucher.vue'
import SuccessUpload from '@/components/modals/SuccessUpload.vue'
import ManualVoucher from '@/components/modals/ManualVoucher.vue'

export default {
  components: {
    Error,
    Upload,
    Success,
    Passport,
    Feedback,
    CodeCheck,
    ClosedModal,
    HeaderRegion,
    FooterRegion,
    ManualVoucher,
    FailedVoucher,
    UploadVoucher,
    SuccessUpload,
  },

  data() {
    return {
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      token: 'token'
    }),
  },

  mounted(){
    setTimeout(() => {
      this.$store.dispatch('getDocuments')
    }, 2500)

    setTimeout(() => {
      if(this.token){
        this.$store.dispatch('getUser')
        // this.$store.dispatch('getCerts')

        axios.defaults.headers.authorization = "Bearer " + this.token
      }
    }, 500)
  },

  methods: {
  },
}
</script>

<style lang="scss">

</style>
